import React, { useState, useEffect, useMemo } from 'react';
import { motion } from "framer-motion";
import styled,{ keyframes } from 'styled-components';
import { useLayoutEffect } from 'react';

import img1 from "../assets/Images/alpableu.webp";
import img1b from "../assets/Images/AlpagaBleu.JPG";
import img1c from "../assets/Images/alpableu3.JPG";
import img2 from "../assets/Images/AlpagaBeiges.webp";
import img2b from "../assets/Images/Alapabeige2.JPG";
import img2c from "../assets/Images/Alpabeige3.JPG";
import img3b from "../assets/Images/alapgared.webp";
import img3c from "../assets/Images/Alpared4.JPG";
import img3 from "../assets/Images/alpared2.JPG";
import img4 from "../assets/Images/aglace2.webp";
import img4b from "../assets/Images/aglace1.JPG";
import img4c from "../assets/Images/Aglace3.JPG";
import img5 from "../assets/Images/MohairFauve.webp";
import img7 from "../assets/Images/Luxuriant.webp";
import img7b from "../assets/Images/luxuriant2.JPG";
import img7c from "../assets/Images/luxuriant3b.JPG";
import img8 from "../assets/Images/HeraldicStarBleu.webp";
import img8b from "../assets/Images/Merino.JPG";
import img8c from "../assets/Images/Merino2.JPG";
import img9 from "../assets/Images/HeraldicFire.webp";
import img9b from "../assets/Images/LiberIgnis2.JPG";
import img10 from "../assets/Images/asinople.webp";
import img10b from "../assets/Images/alpasinople.webp";
import img10c from "../assets/Images/Brown.webp";


const Page = styled.section`
    min-height: 100vh;
    height: auto;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color:  ${(props) => props.theme.body};

`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Josefin Sans";
  font-weight: 300;
  color: ${(props) => props.theme.titlebrun};
  position: absolute;
  top: 0.2rem;
  left: 73%;
  z-index: 10;

  /* @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  } */

  span {
    display: inline-block;
  }
`
const GalleryWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const FilterContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const FilterButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: ${props => props.active ? '#007bff' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.active ? '#0056b3' : '#e0e0e0'};
  }
`;

const MasonryGrid = styled.div`
  column-count: 3;
  column-gap: 20px;
  
  @media (max-width: 968px) {
    column-count: 2;
  }
  
  @media (max-width: 568px) {
    column-count: 1;
  }
`;

const ImageItem = styled.div`
  break-inside: avoid;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  
  &:hover {
    .product-info-overlay {
      opacity: 1;
    }
  }
`;

const ProductInfoOverlay = styled.div.attrs({ className: 'product-info-overlay' })`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.03);
  }
`;

// Modal Components
const modalFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  animation: ${modalFadeIn} 0.3s ease;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  
  &:hover {
    color: #000;
  }
`;

const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProductImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const ProductInfo = styled.div`
  h2 {
    margin-top: 0;
    color: #333;
  }
  
  .price {
    font-size: 24px;
    color: #007bff;
    margin: 10px 0;
  }
  
  .description {
    color: #666;
    margin: 15px 0;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  
  li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
    
    &:before {
      content: "*";
      color: #007bff;
      position: absolute;
      left: 0;
    }
  }
`;

const AddToCartButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }
`;
const ProductImageSlider = styled.div`
  position: relative;
  width: 100%;
`;

const MainImage = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  
  &::-webkit-scrollbar {
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

const Thumbnail = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid ${props => props.active ? '#007bff' : 'transparent'};
  border-radius: 4px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SliderButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 2;
  
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  &.prev {
    left: 10px;
  }
  
  &.next {
    right: 10px;
  }
  
  &:disabled {
    background: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }
`;

const ImageCounter = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
`;


// Main Component
const ProductGallery = ({ products }) => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const categories = useMemo(() => {
    const cats = new Set(products.map(product => product.category));
    return ['all', ...Array.from(cats)];
  }, [products]);

  const filteredProducts = useMemo(() => {
    if (selectedCategory === 'all') {
      return products;
    }
    return products.filter(product => product.category === selectedCategory);
  }, [products, selectedCategory]);

  return (
    <GalleryWrapper>
      <FilterContainer>
        {categories.map(category => (
          <FilterButton
            key={category}
            active={selectedCategory === category}
            onClick={() => setSelectedCategory(category)}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </FilterButton>
        ))}
      </FilterContainer>

      <MasonryGrid>
        {filteredProducts.map((product, index) => (
          <ImageItem key={index} onClick={() => setSelectedProduct(product)}>
            <ProductImage
              src={product.image}
              alt={product.name}
              loading="lazy"
            />
            <ProductInfoOverlay>
              <h3>{product.name}</h3>
              <p>€{product.price}</p>
            </ProductInfoOverlay>
          </ImageItem>
        ))}
      </MasonryGrid>

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </GalleryWrapper>
  );
};

//Zoomable component
const ZoomableImage = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative overflow-hidden group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img 
        src={src} 
        alt={alt} 
        className={`w-full h-auto transition-transform duration-300 ease-in-out 
          ${isHovered ? 'scale-125' : 'scale-100'} 
          origin-center`} 
      />
      
      {isHovered && (
        <div 
          className="absolute inset-0 bg-black bg-opacity-20 
            flex items-center justify-center 
            transition-opacity duration-300"
        />
      )}
    </div>
  );
};

// Enhanced Product Modal Component
const ProductModal = ({ product, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex(prev => 
      prev === 0 ? product.images.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex(prev => 
      prev === product.images.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ProductDetails>
          <ProductImageContainer>
            <ProductImageSlider>
              <MainImage>
                <img 
                  src={product.images[currentImageIndex].url} 
                  alt={product.images[currentImageIndex].alt || product.name}
                />
                <SliderButton 
                  className="prev" 
                  onClick={handlePrevImage}
                  disabled={product.images.length <= 1}
                >
                  &#8249;
                </SliderButton>
                <SliderButton 
                  className="next" 
                  onClick={handleNextImage}
                  disabled={product.images.length <= 1}
                >
                  &#8250;
                </SliderButton>
                <ImageCounter>
                  {currentImageIndex + 1} / {product.images.length}
                </ImageCounter>
              </MainImage>
              <ThumbnailContainer>
                {product.images.map((image, index) => (
                  <Thumbnail
                    key={index}
                    active={currentImageIndex === index}
                    onClick={() => setCurrentImageIndex(index)}
                  >
                    <img src={image.url} alt={image.alt || `${product.name} thumbnail ${index + 1}`} />
                  </Thumbnail>
                ))}
              </ThumbnailContainer>
            </ProductImageSlider>
          </ProductImageContainer>
          <ProductInfo>
            <h2>{product.name}</h2>
            <div className="price">${product.price}</div>
            <div className="description">{product.description}</div>
            <h3>Features</h3>
            <FeaturesList>
              {product.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </FeaturesList>
            <AddToCartButton>Add to Cart</AddToCartButton>
          </ProductInfo>
        </ProductDetails>
      </ModalContent>
    </ModalOverlay>
  );
};


// Example usage:
const Collection = () => {
  const sampleProducts = [
    {
      id: 1,
      name: "AlpaBleu",
      price: 100,
      category: "Alpaga",
      image: img1,
      images: [
        {     
            url: img1,
            alt: "Front view of Alpaga bleu"
        },
        {
          url: img1b,
          alt: "Alpaga bleu 2"
        },
        {
          url: img1c,
          alt: "Alpaga bleu sur métier"
        },
        // {
        //   url: "https://example.com/headphones-case.jpg",
        //   alt: "Headphones with carrying case"
        // }
      ],
      description: "Cette écharpe AlpaBleu a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 27 x 183 cm",
        "composition : 100% Alpaga",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 2,
      name: "Billionaire",
      price: 120,
      category: "Alpaga",
      image: img2,
      images: [
        {
          url: img2,
          alt: "Billionaire scarf"
        },
        {
          url: img2b,
          alt: "Billionnaire scarf 2"
        },
        {
          url: img2c,
          alt: "Billionaire sur métier"
        },
      ],
      description: "Cette écharpe Billionaire a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 28 x 170 cm",
        "composition : 100% Alpaga",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 3,
      name: "AlpaRouge",
      price: 175,
      category: "Alpaga",
      image: img3,
      images: [
        {
          url: img3,
          alt: "Alpaga rouge"
        },
        {
          url: img3b,
          alt: "Alpaga rouge vue 2"
        },
        {
          url: img3c,
          alt: "Alpaga rouge sur métier"
        },
      ],
      description: "Cette écharpe AlpaRouge a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 27,5 x 180 cm",
        "composition : 100% Alpaga",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 4,
      name: "Tartan Glace",
      price: 175,
      category: "Mohair",
      image: img4,
      images: [
        {
          url: img4,
          alt: "Ice Tartan"
        },
        {
          url: img4b,
          alt: "Ice Tartan vue 2"
        },
        {
          url: img4c,
          alt: "Ice Tartan sur métier"
        },
      ],
      description: "Cette écharpe Tartan Glace a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 35 x 160 cm",
        "composition : 100% Pure Mohair",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 5,
      name: "tartan Fauve",
      price: 195,
      category: "Mohair",
      image: img5,
      images: [
        {
          url: img5,
          alt: "Billionaire scarf"
        },
        // {
        //   url: img2b,
        //   alt: "Billionnaire scarf 2"
        // },
        // {
        //   url: img2c,
        //   alt: "Billionaire sur métier"
        // },
      ],
      description: "Cette écharpe Tartan Fauve a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 29 x 175 cm",
        "composition : 100% Pure Mohair",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 7,
      name: "The Luxuriant",
      price: 275,
      category: "Mohair",
      image: img7,
      images: [
        {
          url: img7,
          alt: "The luxuriant Mohair"
        },
        {
          url: img7b,
          alt: "The Luxuriant Mohair vue 2"
        },
        {
          url: img7c,
          alt: "The Luxuriant Mohair vue 2"
        },
      ],
      description: "Cette écharpe Luxuriant a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 31 x 183 cm",
        "composition : 98% Mohair, 2% Soie",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 10,
      name: "Tartan Sinople",
      price: 195,
      category: "Mohair",
      image: img10b,
      images: [
        {
          url: img10b,
          alt: "Tartan sinople"
        },
        {
          url: img10,
          alt: "Tartan sinople vue 2"
        },
        {
          url: img10c,
          alt: "Tartan sinople sur modèle"
        },
      ],
      description: "Cette écharpe Tartan Sinople a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 28,5 x 174 cm",
        "composition : 80% Mohair, 20% Soie",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 8,
      name: "Librum Aquae",
      price: 195,
      category: "Merinos",
      image: img8,
      images: [
        {
          url: img8,
          alt: "Librum Aquae Merinos"
        },
        {
          url: img8b,
          alt: "Librum Aquae Merinos sur modèle"
        },
        {
          url: img8c,
          alt: "Librum Aquae Merinos sur métier"
        },
      ],
      description: "Cette écharpe Librum Aquae a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 35 x 165 cm",
        "composition : 56% Laine, 44% Coton",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    {
      id: 9,
      name: "Liber Ignis",
      price: 155,
      category: "Merinos",
      image: img9,
      images: [
        {
          url: img9,
          alt: "Liber Ignis Merinos"
        },
        {
          url: img9b,
          alt: "Liber Ignis sur métier"
        },
      ],
      description: "Cette écharpe Liber Ignis a été entièrement déssinée et tissée à la main par John.C",
      features: [
        "dimensions : 35 x 155 cm",
        "composition : 52% Laine, 48% Coton",
        "100% tissée main",
        "origine : Hautes-Alpes"
      ]
    },
    // ... more images
  ];

  return (
    <Page id="Collection" >
    <Title data-scroll data-scroll-speed="1" data-scroll-direction="horizontal">
        Collections
    </Title>
    <ProductGallery products={sampleProducts} />
    </Page>
  );
};



export default Collection